
import { Vue, Component } from 'vue-property-decorator';
import { clone, omit, pick } from 'ramda';

import TermsOfService from '@/components/TermsOfService.vue';

import { Auth } from '@/store/modules';
import { User, UserDetails } from '@/store/auth';

@Component({
  name: "Terms",
  components: {
    TermsOfService
  },
  metaInfo: { 
    title: 'Terms of Service | SkillToon - Master New Skills with Live Interactive Workshops',
    link: [
      { rel: 'canonical', href: 'https://skilltoon.com/terms' }
    ],
    meta: [
      { property: 'og:url', content: 'https://skilltoon.com/login' },
      { property: 'og:type', content: 'website' },
      { property: 'og:title', content: 'Terms of Service | SkillToon - New Way of Teaching & Learning Skills' },
      { property: 'og:description', content: 'Join SkillToon to learn, create, acquire and teach skills in your area of passion at home. Explore today!' },
      { property: 'og:image', content: 'https://app-dev.skilltoon.com/logo-color.svg' },
      { name: 'description', content: 'Dive into our immersive live workshops where every session is a stepping stone towards mastering your skills, connecting with experts, and transforming your passion into expertise. Join SkillToon to start shaping your future today!' },
      { name: 'keywords', content: 'SkillToon' },
      { name: 'robots', content: 'index,follow' },
      { property: 'twitter:card', content: 'summary_large_image' },
      { property: 'twitter:url', content: 'https://skilltoon.com/login' },
      { property: 'twitter:title', content: 'Terms of Service | SkillToon - New Way of Teaching & Learning Skills' },
      { property: 'twitter:description', content: 'Join SkillToon to learn, create, acquire and teach skills in your area of passion at home. Explore today!' },
      { property: 'twitter:image', content: 'https://app-dev.skilltoon.com/logo-color.svg' },
    ]
  }
})
export default class extends Vue{
  termsConditions = false;
  loading = false;
  error = '';
  errorSave = '';
  model: Partial<User> = {
    details: {},
  };
  avatarUrl = '';
  loggedIn = Auth.loggedIn;
  acceptedTermsConditions: boolean = Auth.termsConditions || false;

  async refresh() {
    try {
      const user = await Auth.getSelfInformation();
      this.model = clone(user);

      
      if (!this.model.details) {
        this.model.details = {};
      }

      this.avatarUrl = this.model.avatarUrl || '';
    } catch (err) {
      this.error = err.message;
    }
  }

  mounted() {
    if (this.loggedIn) {
      this.refresh();
    }
  }

  async save() {
    this.loading = true;
    try {
      const toUpdateDetails: UserDetails = omit(['social_account'], this.model.details);
      const toUpdate: Partial<User> = pick(['email', 'name', 'role'], this.model);
      toUpdateDetails.termsConditions = this.termsConditions;
      toUpdate.details = toUpdateDetails;

      await Auth.updateAccount(toUpdate);

      this.$router.push('/');

    } catch (err) {
      this.errorSave = err.message;
    }
    this.loading = false;
  }
}
